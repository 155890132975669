<template>
    <v-container fluid fill-height class="d-flex justify-center align-center">
        <v-card flat outlined>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" class="d-flex justify-center align-sm-center">
                        <div>
                            <div class="display-2 font-weight-bold">{{ $t('oops') }}</div>
                            <p class="title">{{ $t('error_adding_container') }}</p>
                            <p class="body-2">{{ $t('try_adding_container_again') }}</p>
                            <v-btn :to="{name: 'entrance'}">{{ $t('try_again') }}</v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" class="d-flex justify-center justify-sm-end">
                        <v-img src="/img/container.png" contain></v-img>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    name: "ContainerError"
}
</script>
